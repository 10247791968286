import { Box } from "@mui/material";
import React from "react";
import { colors } from "../../constants/colors";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", padding: "10px", color:colors.white, opacity:"0.5" }}>
      ©{currentYear} | All rights reserved | Jeeva Oli Ministry
    </Box>
  );
};

export default Footer;
