import React, { useState, useEffect } from "react";
import { Box, Divider, keyframes, Typography, styled } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import TopBar from "../../components/TopBar/TopBar";
import { AppData } from "../../constants/AppData";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import { colors } from "../../constants/colors";
import { ArrowBack } from "@mui/icons-material";
import Footer from "../../components/Footer/Footer";
import { useSelector } from "react-redux";

const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  minHeight: "100vh", // Change this line
  background: "#343541",
  [theme.breakpoints.down("lg")]: {
    minHeight: "100%", // Change this line
  },
  [theme.breakpoints.down("lg")]: {
    height: "auto", // Change this line
  },
}));

// Define the pulse effect using keyframes
const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

const TracksContainer = styled(Box)(() => ({
  padding: "20px 30px",
  paddingTop: "100px",
}));

const TrackItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  border: "1px solid",
  borderColor: colors.white,
  borderRadius: "10px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    borderColor: colors.primary,
    "& .play-controller-btn": {
      animation: `${pulse} 2s infinite`, // Apply animation to PlayControllerBtnContainer on hover
    },
  },
}));

const BackArrowBox = styled(Box)(() => ({
  cursor: "pointer",
  background: "gray",
  height: "30px",
  width: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "10px",
}));
const TitleContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "20px",
}));

const CategoryTitle = styled(Typography)(() => ({
  color: colors.primary,
  fontSize: "24px",
  fontFamily: "Ubuntu",
}));

const PlayControllerBtnContainer = styled(Typography)(() => ({
 
  borderRadius: "50px",
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
}));
const TracksPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { categoryName } = location.state;
  const [playingTrack, setPlayingTrack] = useState(null);
  const [audio, setAudio] = useState(null);

  // Effect for audio cleanup
  useEffect(() => {
    return () => {
      if (audio) {
        audio.pause();
        audio.src = ""; // Reset the source to release the audio object
      }
    };
  }, [audio]);

  const togglePlayPause = (track) => {
    console.log(track);
    if (playingTrack === track.id) {
      if (audio && !audio.paused) {
        audio.pause();
        setPlayingTrack(null);
      } else {
        audio.play();
      }
    } else {
      if (audio && !audio.paused) {
        audio.pause();
      }
      const newAudio = new Audio(track.audioURL);
      newAudio.play();
      setAudio(newAudio);
      setPlayingTrack(track.id);

      // Properly handle play/pause state changes
      newAudio.onplay = () => setPlayingTrack(track.id);
      newAudio.onpause = () => setPlayingTrack(null);
      newAudio.onended = () => setPlayingTrack(null);
    }
  };
  const selectedLanguage = useSelector((state) => state.globalState.selectedLanguage);
  const categoriesList = selectedLanguage==='English'?AppData.engCategories:AppData.tamCategories;

  const category = categoriesList.find((c) => c.name === categoryName);

  return (
    <Root>
      <Box>
        <TopBar />
        <TracksContainer>
          <TitleContainer>
            <BackArrowBox
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack sx={{ color: colors.white }} />
            </BackArrowBox>

            <CategoryTitle>{categoryName}</CategoryTitle>
          </TitleContainer>
          <Divider color={"gray"} sx={{ mt: 3, mb: 6 }} />
          <Box>
            {category?.content.map((track) => (
              <TrackItem key={track.id}>
                <Typography variant="h6" color={colors.white}>
                  {track.name}
                </Typography>
                <PlayControllerBtnContainer
                className="play-controller-btn" 
                  onClick={() => togglePlayPause(track)}
                >
                  {playingTrack === track.id && audio && !audio.paused ? (
                    <PauseCircleIcon
                      sx={{
                        color: colors.white,
                        height: "50px",
                        width: "50px",
                      }}
                    />
                  ) : (
                    <PlayCircleIcon
                      sx={{
                        color: colors.white,
                        height: "50px",
                        width: "50px",
                      }}
                    />
                  )}
                </PlayControllerBtnContainer>
              </TrackItem>
            ))}
          </Box>
        </TracksContainer>
      </Box>

      <Footer />
    </Root>
  );
};

export default TracksPage;
