import { createSlice } from "@reduxjs/toolkit";

export const globalStateSlice = createSlice({
  name: "globalState",
  initialState: {
    selectedLanguage: "தமிழ்",
    password: ""
  },
  reducers: {
    changeSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
    changePassword: (state, action) => {
      state.password = action.payload;
    }
  }
});

export const { changeSelectedLanguage, changePassword } = globalStateSlice.actions;

export const CurrentSelectedLanguage = (state) => state.globalState.selectedLanguage;
export const CurrentPassword = (state) => state.globalState.password;

export default globalStateSlice.reducer;
