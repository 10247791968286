import React from "react";
import { Box, Divider, Typography, styled } from "@mui/material";
import TopBar from "../../components/TopBar/TopBar";
import { AppData } from "../../constants/AppData";
import { useNavigate } from "react-router-dom";
import { colors } from "../../constants/colors";
import { ArrowBack } from "@mui/icons-material";
import Footer from "../../components/Footer/Footer";
import { useSelector } from "react-redux";

const Root = styled(Box)(({ theme }) => ({
  minHeight: "100vh", // Change this line
  background: "#343541",
  [theme.breakpoints.down("lg")]: {
    minHeight: "100%", // Change this line
  },
}));

// Create a styled container for the categories
const CategoryContainer = styled(Box)(() => ({
  padding: "20px 30px",
  paddingTop: "100px",
}));

// Create a styled Typography for the category title
const CategoryTitle = styled(Typography)(() => ({
  color: colors.primary,
  fontSize: "24px",
  fontFamily: "Ubuntu",
}));

const CategoriesContainer = styled(Typography)(() => ({
  display: "flex",
  gap: "50px",
  marginTop: "20px",
  flexWrap: "wrap",
  // justifyContent: "space-between",
}));

// Create a styled Box for individual category items
const CategoryItem = styled(Box)(() => ({
  width: "400px",
  height: "150px",
  border: `1px solid ${colors.white}`,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
  gap: "20px",
  "&:hover": {
    border: `1px solid ${colors.primary}`, // Change border color to gold on hover
  },
}));

const BackArrowBox = styled(Box)(() => ({
  cursor: "pointer",
  background: "gray",
  height: "30px",
  width: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "10px",
}));

const ViewBtn = styled(Box)(() => ({
  height: "40px",
  width: "100px",
  background: colors.primary,
  color: colors.black,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "10px",
  cursor: "pointer",
  fontFamily: "Ubuntu",
  fontWeight: "600",
}));
const TitleContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "20px",
}));

const CategoriesPage = () => {
  const navigate = useNavigate();
  const selectedLanguage = useSelector((state) => state.globalState.selectedLanguage);
  const categoriesList = selectedLanguage==='English'?AppData.engCategories:AppData.tamCategories;

  const onCategoryClick = (categoryName) => {
    // navigate("/tracks", { state: { categoryName } });
    navigate("/videos", { state: { categoryName } });
  };

  return (
    <Root>
      <TopBar isTransparent={false}/>
      <CategoryContainer>
        <TitleContainer>
          <BackArrowBox
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBack sx={{ color: colors.white }} />
          </BackArrowBox>

          <CategoryTitle>Categories</CategoryTitle>
        </TitleContainer>

        <Divider color={"gray"} sx={{ mt: 3, mb: 6 }} />
        <CategoriesContainer>
          {categoriesList.map((item, index) => (
            <CategoryItem
              key={index}
              onClick={() => onCategoryClick(item.name)}
            >
              <Typography
                sx={{
                  color: colors.white,
                  fontSize: "20px",
                  fontFamily: "Ubuntu",
                }}
              >
                {item.name}
              </Typography>
              <ViewBtn>View</ViewBtn>
            </CategoryItem>
          ))}
        </CategoriesContainer>
      </CategoryContainer>

      <Footer />
    </Root>
  );
};

export default CategoriesPage;
