import React, { useState } from "react";
import {
  Box,
  Divider,
  keyframes,
  Typography,
  styled,
  Dialog,
  Stack,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import TopBar from "../../components/TopBar/TopBar";
import { AppData } from "../../constants/AppData";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
// import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import { colors } from "../../constants/colors";
import { ArrowBack } from "@mui/icons-material";
import Footer from "../../components/Footer/Footer";
import { useSelector } from "react-redux";
import { Close } from "@mui/icons-material";

const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  minHeight: "100vh", // Change this line
  background: "#343541",
  [theme.breakpoints.down("lg")]: {
    minHeight: "100%", // Change this line
  },
  [theme.breakpoints.down("lg")]: {
    height: "auto", // Change this line
  },
}));

// Define the pulse effect using keyframes
const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

const TracksContainer = styled(Box)(() => ({
  padding: "20px 30px",
  paddingTop: "100px",
}));

const TrackItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  border: "1px solid",
  borderColor: colors.white,
  borderRadius: "10px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    borderColor: colors.primary,
    "& .play-controller-btn": {
      animation: `${pulse} 2s infinite`, // Apply animation to PlayControllerBtnContainer on hover
    },
  },
}));

const BackArrowBox = styled(Box)(() => ({
  cursor: "pointer",
  background: "gray",
  height: "30px",
  width: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "10px",
}));
const TitleContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "20px",
}));

const CategoryTitle = styled(Typography)(() => ({
  color: colors.primary,
  fontSize: "24px",
  fontFamily: "Ubuntu",
}));

const PlayControllerBtnContainer = styled(Typography)(() => ({
  borderRadius: "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
const VideosPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [iframeVideoURL, setiframeVideoURL] = useState("");
  const [iframeVideoTitle, setiframeVideoTitle] = useState("");
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { categoryName } = location.state;

  const selectedLanguage = useSelector(
    (state) => state.globalState.selectedLanguage
  );
  const categoriesList =
    selectedLanguage === "English"
      ? AppData.engCategories
      : AppData.tamCategories;

  const category = categoriesList.find((c) => c.name === categoryName);

  const openIframeInModal = (track) => {
    // console.log("url", url);
    setiframeVideoURL(track.videoURL);
    setiframeVideoTitle(track.name);
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <Root>
      <Box>
        <TopBar />
        <TracksContainer>
          <TitleContainer>
            <BackArrowBox
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack sx={{ color: colors.white }} />
            </BackArrowBox>

            <CategoryTitle>{categoryName}</CategoryTitle>
          </TitleContainer>
          <Divider color={"gray"} sx={{ mt: 3, mb: 6 }} />
          <Box>
            {category?.content.map((track) => (
              <TrackItem key={track.id}>
                <Typography variant="h6" color={colors.white}>
                  {track.name}
                </Typography>
                <PlayControllerBtnContainer
                  className="play-controller-btn"
                  onClick={() => openIframeInModal(track)}
                >
                  <PlayCircleIcon
                    sx={{
                      color: colors.white,
                      height: "50px",
                      width: "50px",
                    }}
                  />
                </PlayControllerBtnContainer>
              </TrackItem>
            ))}
          </Box>
        </TracksContainer>
      </Box>

      <Dialog open={dialogOpen} onClose={handleClose} >
        <Box
          sx={{
            padding: "20px",
            background: "black",
            border: "1px solid grey",
            
          }}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography sx={{ color: "white" }}>{iframeVideoTitle}</Typography>
            <Box onClick={handleClose}>
              <Close style={{ color: "white" }} />
            </Box>
          </Stack>

          <Box
            sx={{
              display: "flex",
              gap: "15px",
              justifyContent: "center",
              marginTop: "30px",
              width: "500px",
              height: "300px",
            }}
          >
            <iframe
              src={iframeVideoURL}
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
              title="video"
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
        </Box>
      </Dialog>

      <Footer />
    </Root>
  );
};

export default VideosPage;
