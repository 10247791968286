import React, { useState } from "react";
import {
  Box,
  Typography,
  styled,
  useMediaQuery,
  Menu,
  MenuItem,
  IconButton,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { colors } from "../../constants/colors";


// Create a styled Box for the top bar
const TopBarBox = styled(Box)(({ theme, isTransparent }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  padding: isTransparent?"20px 30px":"10px 30px",
  boxSizing: "border-box",
  backgroundColor: isTransparent?'none':colors.primary,
  position: 'fixed', // Make the top bar fixed
  // top: 0, // Fix it at the top of the viewport
  // zIndex: 1100, 
  // height: "70px",
}));

// Create a styled Typography for the title with responsive font size
const TitleTypography = styled(Typography)(({ theme,isTransparent }) => ({
  fontSize: "28px",
  fontWeight: "600",
  color:isTransparent?colors.primary:colors.black,
  fontFamily:"Ubuntu",
  [theme.breakpoints.down("sm")]: {
    fontSize: "24px", // smaller font size for mobile
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "26px", // slightly larger font size for tablets
  },
}));

// Styled Box for navigation links
const NavLinkBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "50px",
}));


const TopBar = ({isTransparent}) => {
  const theme = useTheme();
  console.log(isTransparent)
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLinkClick = (link) => {
    navigate('/')
  }
  return (
    <TopBarBox isTransparent={isTransparent} position={'sticky'}>
      <Box onClick ={()=>{navigate('/')}} sx={{cursor:"pointer"}}>
        <TitleTypography isTransparent={isTransparent}>BrightBibleTales</TitleTypography>
      </Box>
      {isMobile ? (
        <>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenu}
          >
            <MenuIcon sx={{color:"gold"}}/>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLinkClick}>Home</MenuItem>
            <MenuItem onClick={handleClose}>About</MenuItem>
          </Menu>
        </>
      ) : (
        <NavLinkBox>
          <Typography color={isTransparent?colors.primary:colors.black} onClick={handleLinkClick} sx={{cursor:"pointer", fontFamily:"Ubuntu",}} >Home</Typography>
          <Typography color={isTransparent?colors.primary:colors.black} sx={{cursor:"pointer", fontFamily:"Ubuntu",}}>About</Typography>
        </NavLinkBox>
      )}
    </TopBarBox>
  );
};

export default TopBar;
