import React, { useEffect } from "react";
import { Box, Typography, styled, keyframes, Stack } from "@mui/material";
import backgroundImage from "../../assets/images/backgroundImage.png";
import { useNavigate } from "react-router-dom";
import TopBar from "../../components/TopBar/TopBar";
import { Dialog } from "@mui/material";
import { Close } from "@mui/icons-material";
import { colors } from "../../constants/colors";
import Footer from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedLanguage } from "../../reduxStore/globalStateSlice";

// Create a styled Box with background image
const BackgroundBox = styled(Box)(() => ({
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "100vh",
  color: colors.white,
  width: "100%",
  backgroundColor: colors.black,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

// Main content box
const ContentBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  marginTop: "150px",
}));

// Main headline Typography with responsive font size
const HeadlineTypography = styled(Typography)(({ theme }) => ({
  fontSize: "60px",
  fontWeight: 600,
  textAlign: "center",
  color: colors.primary,
  fontFamily: "Ubuntu",
  textShadow:
    "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
  [theme.breakpoints.down("sm")]: {
    fontSize: "40px", // smaller font size for mobile
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "50px", // slightly larger font size for tablets
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "30px",
  fontWeight: 400,
  textAlign: "center",
  color: colors.primary,
  fontFamily: "Ubuntu",
  textShadow:
    "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px", // smaller font size for mobile
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "20px", // slightly larger font size for tablets
  },
}));

// Define the pulse effect using keyframes
const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

// Styled Box for the start listening button with pulse effect
const StartListeningBox = styled(Box)(() => ({
  background: colors.primary,
  borderRadius: "50px",
  width: "200px",
  height: "60px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: colors.black,
  fontWeight: 600,
  fontSize: "20px",
  cursor: "pointer",
  fontFamily: "Ubuntu",
  animation: `${pulse} 2s infinite`,
  border: "1px solid black",
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", // Elevated shadow effect
}));

const StartListeningDialogBox = styled(Box)(() => ({
  background: colors.primary,
  borderRadius: "10px",
  width: "100%",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#000",
  fontWeight: 600,
  fontSize: "16px",
  cursor: "pointer",
  fontFamily: "Ubuntu",
  marginTop: "30px",
}));

const HomePage = () => {
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const selectedLanguage = useSelector(
    (state) => state.globalState.selectedLanguage
  );
  const dispatch = useDispatch();

  const languageSupportList = ["தமிழ்", "English"];
  const onStartListenBtnClick = () => {
    window.webengage.track("StartListeningBtnCLick", {
      btnClickedTime: new Date().toISOString(),
    });
    setDialogOpen(true);
    // navigate("/categories");
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  const moveToCategories = () => {
    window.webengage.track("gone to categories page", {
      btnClickedTime: new Date().toISOString(),
      selectedLanguage: selectedLanguage,
    });
    navigate("/categories");
  };

  useEffect(() => {
    if (!localStorage.getItem("userID")) {
      // Create a new unique user ID
      const userID = "user-" + Date.now();
      localStorage.setItem("userID", userID);
    }
    // Retrieve the user ID
    const userID = localStorage.getItem("userID");

    window.webengage.user.login(userID);
    // version 2
  }, []);

  return (
    <BackgroundBox>
      <Box>
        <TopBar isTransparent={true} />

        <ContentBox>
          <Stack>
            <HeadlineTypography>Hear the Word come alive</HeadlineTypography>
            <SubTitle>Stories of Faith in Tamil & English</SubTitle>
          </Stack>

          <StartListeningBox onClick={onStartListenBtnClick}>
            Start Listening
          </StartListeningBox>
        </ContentBox>
      </Box>

      <Footer />

      <Dialog open={dialogOpen} onClose={handleClose}>
        <Box sx={{ padding: "20px", background: "white" }}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography sx={{ color: "black" }}>
              Choose your language
            </Typography>
            <Box onClick={handleClose}>
              <Close />
            </Box>
          </Stack>

          <Box
            sx={{
              display: "flex",
              gap: "15px",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            {languageSupportList.map((language, index) => (
              <Box
                key={index}
                onClick={() => dispatch(changeSelectedLanguage(language))}
                sx={{
                  height: "50px",
                  width: "200px",
                  border: `2px solid ${
                    selectedLanguage === language ? colors.primary : "grey"
                  }`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              >
                {language}
              </Box>
            ))}
          </Box>

          <StartListeningDialogBox onClick={moveToCategories}>
            Start Listening
          </StartListeningDialogBox>
        </Box>
      </Dialog>
    </BackgroundBox>
  );
};

export default HomePage;
